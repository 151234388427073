import { useEffect, useRef, useState } from "react"

import { ReactComponent as IconUpgrade } from "@common_assets/svg/upgradeArrow.svg"
import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useCustomPayment } from "hooks/payment"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { UserSchoolPlan } from "ts/enums/User"
import { IUserMe } from "ts/interfaces/User"

interface IProps {
  user: IUserMe | null
}

interface CustomFromState {
  state: {
    isStripe: boolean
  }
}

interface CustomState {
  from: CustomFromState
}

const UpgradeAccount = ({ user }: IProps) => {
  const { payIt, isLoadingPayments } = useCustomPayment()
  const { t } = useTranslation()
  const isMobile = useIsBreakpointUp("tablet")
  const buttonRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const [hasPaid, setHasPaid] = useState(false)
  const state = location.state as CustomState | undefined

  const checkingStripe = () => {
    const href = location.search.includes("isStripe")
    const stateIsStripe = state?.from.state?.isStripe

    return !!(href || stateIsStripe)
  }

  const clearState = () => {
    navigate("/", { replace: true, state: null })
  }

  useEffect(() => {
    if (buttonRef.current && checkingStripe()) {
      buttonRef.current.click()
      setHasPaid(true)
    }
  }, [])

  useEffect(() => {
    if (hasPaid) {
      clearState()
    }
  }, [hasPaid])

  return (
    <>
      {process.env.REACT_APP_TYPE === "classequity" &&
        user?.user.school_plan === UserSchoolPlan.FREE && (
          <>
            {isLoadingPayments && (
              <span style={{ marginRight: "5px" }}>
                <CircularProgress size="16px" />
              </span>
            )}
            {!isMobile ? (
              <Box
                onClick={() => {
                  payIt(null)
                  mixpanel.track("plan_upgrade", {
                    action: "upgrade_button_topBar",
                  })
                }}
                sx={{
                  mr: 1,
                  pointerEvents: isLoadingPayments ? "none" : "all",
                  opacity: isLoadingPayments ? "0.3" : "1",
                }}
              >
                <IconUpgrade />
              </Box>
            ) : (
              <Stack
                ref={buttonRef}
                sx={(theme) => ({
                  pointerEvents: isLoadingPayments ? "none" : "all",
                  opacity: isLoadingPayments ? "0.3" : "1",
                  cursor: "pointer",
                  backgroundColor: theme.palette.mockup.primary95,
                  p: 1,
                  borderRadius: "8px",
                  mr: 2,
                  ":hover": {
                    transition: "opacity .3s",
                    opacity: "0.7",
                  },
                })}
                onClick={() => {
                  payIt(null)
                  mixpanel.track("plan_upgrade", {
                    action: "upgrade_button_topBar",
                  })
                }}
              >
                <Typography color="mockup.primary60">
                  {t("teacherSettings.upgradePlan")}
                </Typography>
              </Stack>
            )}
          </>
        )}
    </>
  )
}

export default UpgradeAccount
