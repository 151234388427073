import { useState, useEffect, useReducer } from "react"

import { ReactComponent as IconPlus } from "@common_assets/svg/plus.svg"
import { ReactComponent as IconQuestion } from "@common_assets/svg/question.svg"
import { ReactComponent as IconSettings } from "@common_assets/svg/settings.svg"
import {
  Box,
  CircularProgress,
  Fab,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material"
import {
  useMutationAddNewClass,
  useMutationDeleteClass,
  useMutationEditClass,
  useMutationMathClass,
} from "api/reactQuery/mutations/classes"
import {
  useQueryClasses,
  useQueryClassesById,
} from "api/reactQuery/queries/classes"
import Dialog from "components/common/dialog/dialog"
import ErrorText from "components/common/error/errorText"
import DynamicIcon from "components/common/icon/dynamicIcon"
import IconWrapper from "components/common/icon/iconWrapper"
import AddNewClass from "components/module/addNewClass"
import { IResponseNewClass } from "components/module/addNewClass/AddNewClass.types"
import { useDialog } from "hooks/dialog"
import { usePopover } from "hooks/popover"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch, useAppSelector } from "hooks/store"
import {
  initialStateMathOptions,
  reducer,
} from "layout/asideNav/navigation/Navigation.utility"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectUser } from "store/auth/auth.selectors"
import { setDisplaySavings } from "store/displayMode/displayMode.slice"
import { setClassList } from "store/utility/utility.slice"
import { PopRow, PopOverStyle } from "styles/common/poprow"
import { IClassShort } from "ts/interfaces/Class"
import {
  checkSubscription,
  getErrorMessage,
  getErrorMessageFromTab,
} from "utils/api"

import ApplySettings from "../applySettings"
import { applySettingsDB } from "../applySettings/ApplySettings.config"
import NavItem from "../navItem"
import { initialState } from "./Navigation.config"

const BoxStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: theme.palette.mockup.primary30,
    "#fabHover": {
      display: "block",
    },
  },
  [theme.breakpoints.down("desktop")]: {
    "#fabHover": {
      display: "block",
    },
  },
}))

const Navigation = () => {
  const userInfo = useAppSelector(selectUser)
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [open, setOpen] = useState(false)
  const [className, setClassName] = useState<string>("")
  const [state, setState] = useState<IResponseNewClass>(initialState)
  const [mathOptionStates, dispatchState] = useReducer(
    reducer,
    initialStateMathOptions
  )

  const [id, setId] = useState<number | null>(null)
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const {
    data,
    isLoading,
    isError,
    refetch: refetchClass,
  } = useQueryClasses({
    options: {
      enabled: checkSubscription(userInfo),
    },
  })

  const directRoute = (classObj: IClassShort) =>
    !location.pathname.includes("student-helpers")
      ? RoutePath.CLASS.replace(":classId", `${classObj.id}`)
      : RoutePath.CLASS_STUDENT_HELPERS.replace(":classId", `${classObj.id}`)

  const { popoverAnchorEl, handleClick, handleClose, setPopoverAnchorEl } =
    usePopover<HTMLButtonElement>()

  const { data: getDataId, refetch } = useQueryClassesById({
    id: Number(id),
    options: {
      enabled: open,
      onSuccess: (data) => {
        setState(data.data)
      },
    },
  })

  const cleanAfterCloseDialog = () => {
    applySettingsDB.forEach((el) => (el.checkbox = false))
  }

  const {
    mutate: createNewClass,
    isLoading: isLoadingCreateClass,
    data: dataAfterCreatedClass,
  } = useMutationAddNewClass({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("classes")
        showSnackbar({
          title: t("addNewClass.classCreatedSucceed"),
        })
        if (!!window.Intercom) {
          window.Intercom("trackEvent", "created_class")
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "created_class",
        })
        handleCloseDialogNewClass()
        dispatch(setClassList([]))
      },
      onError: (err) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(err)),
          variant: "error",
        })
      },
    },
  })

  const { mutate: mathClassMutation } = useMutationMathClass({
    options: {
      onSuccess: () => {},
      onError: (err) => {
        showSnackbar({
          title: getErrorMessageFromTab(err),
          variant: "error",
        })
      },
    },
  })

  useEffect(() => {
    setPopoverAnchorEl(null)
  }, [])

  const {
    isOpen: isDialogOpenNewClass,
    handleOpen: handleOpenDialogNewClass,
    handleClose: handleCloseDialogNewClass,
  } = useDialog()

  useEffect(() => {
    !isLoadingCreateClass &&
      dataAfterCreatedClass !== undefined &&
      navigate(
        RoutePath.CLASS.replace(":classId", `${dataAfterCreatedClass?.data.id}`)
      )
  }, [isLoadingCreateClass])

  const {
    isOpen: isDialogOpenEditClass,
    handleOpen: handleOpenDialogEditClass,
    handleClose: handleCloseDialogEditClass,
  } = useDialog()

  const {
    isOpen: isDialogOpenDeleteClass,
    handleOpen: handleOpenDialogDeleteClass,
    handleClose: handleCloseDialogDeleteClass,
  } = useDialog()

  const {
    isOpen: isDialogOpenApplySettings,
    handleOpen: handleOpenDialogApplySettings,
    handleClose: handleCloseDialogApplySettings,
  } = useDialog()

  const { mutate: editClass } = useMutationEditClass({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(["classes"])
        showSnackbar({
          title: t("addNewClass.editSucceed"),
          variant: "success",
        })
        handleCloseDialogEditClass()
        if (!state.savings_account_option) {
          dispatch(setDisplaySavings(false))
          localStorage.setItem("switchState", JSON.stringify(false))
        }
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessageFromTab(error)),
          variant: "error",
        })
      },
    },
  })

  const { mutate: deleteClass } = useMutationDeleteClass({
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries("dashboardTodo")
        if (id && location.pathname.includes(id.toString())) {
          navigate(RoutePath.HOMEPAGE)
        }
        refetchClass()
        showSnackbar({
          title: t("addNewClass.classHasBeenDeletedSuccessful"),
        })
        setId(null)
        handleCloseDialogDeleteClass()
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessage(error),
          variant: "error",
        })
      },
    },
  })

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack flex="1 1 auto" component="nav">
        <NavItem
          text={t("layout.dashboard")}
          to={RoutePath.HOMEPAGE}
          Icon={<DynamicIcon name="home" variant="outlined" />}
          IconActive={<DynamicIcon name="home" variant="solid" />}
        />
        <NavItem
          text={t("layout.allTransactions")}
          to={RoutePath.ALL_TRANSACTIONS}
          Icon={<DynamicIcon name="document" variant="outlined" />}
          IconActive={<DynamicIcon name="document" variant="solid" />}
          onClick={() => {
            mixpanel.track("all_transactions")
          }}
        />
        <Stack
          mt="16px"
          mb="8px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="mockup.neutral100" variant="subtitle2">
            {t("layout.classes")}
          </Typography>
          <IconWrapper
            color="mockup.neutral100"
            sx={{ cursor: "pointer", mr: 1 }}
          >
            <IconPlus
              onClick={() => {
                setState(initialState)
                mixpanel.track("create_class", {
                  event_name: "open_form",
                })
                handleOpenDialogNewClass()
              }}
            />
          </IconWrapper>
          <Dialog
            titleText={t("addNewClass.addNewClass")}
            open={isDialogOpenNewClass}
            onClose={() => {
              handleCloseDialogNewClass()
              mixpanel.track("autopay_state", {
                Action: "cancel",
                Origin: "create_class",
              })
            }}
            actionAcceptText={
              isLoadingCreateClass
                ? t("addNewClass.creatingClass")
                : t("addNewClass.save")
            }
            desktopMaxWidth="534px"
            tabletMaxWidth="534px"
            actionAcceptButtonProps={{
              type: "submit",
              disabled: isLoadingCreateClass,
              form: "form",
            }}
          >
            <AddNewClass
              state={state}
              setState={setState}
              id="form"
              createNewClass={createNewClass}
              dispatchState={dispatchState}
              allClasses={data?.data}
              userInfo={userInfo}
            />
          </Dialog>
        </Stack>
        <Stack flex="1 1 auto" position="relative">
          <Stack
            position="absolute"
            top="0"
            width="100%"
            rowGap="8px"
            sx={{ overflowY: "auto" }}
            height="100%"
            className="smooth-scrollbar"
          >
            {isLoading && (
              <Box width="100%" display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {isError && <ErrorText />}
            {data?.data
              .sort((a, b) =>
                a.name.localeCompare(b.name, undefined, { numeric: true })
              )
              .map((classObj) => (
                <BoxStyle key={classObj.id}>
                  <NavItem
                    onClick={() => {
                      mixpanel.track("students_class")
                    }}
                    text={classObj.name}
                    to={directRoute(classObj)}
                    Icon={
                      <DynamicIcon name={classObj.icon} variant="outlined" />
                    }
                    IconActive={
                      <DynamicIcon name={classObj.icon} variant="solid" />
                    }
                    linkActiveRoutes={[
                      `${RoutePath.CLASS.replace(
                        ":classId",
                        `${classObj.id}`
                      )}/*`,
                    ]}
                  />
                  <Fab
                    id="fabHover"
                    variant="contained"
                    size="small"
                    sx={{
                      mr: "6px",
                      alignSelf: "center",
                      display: "none",
                    }}
                    onClick={(event) => {
                      handleClick(event)
                      setId(classObj.id)
                      setClassName(classObj.name)
                      mixpanel.track("settings_wheel", {
                        action: "click",
                      })
                    }}
                  >
                    <IconSettings style={{ margin: "auto", height: "100%" }} />
                  </Fab>
                  <PopOverStyle
                    open={!!popoverAnchorEl}
                    onClose={handleClose}
                    anchorEl={popoverAnchorEl}
                  >
                    <ul>
                      <PopRow
                        onClick={() => {
                          handleClose()
                          setOpen(true)
                          refetch()
                          handleOpenDialogEditClass()
                          mixpanel.track("edit_class", {
                            action: "click",
                          })
                        }}
                      >
                        <li>{t("addNewClass.editClass")}</li>
                      </PopRow>
                      <PopRow
                        onClick={() => {
                          handleClose()
                          handleOpenDialogApplySettings()
                          refetch()
                          mixpanel.track("import_settings", {
                            action: "click",
                          })
                        }}
                      >
                        <li>{t("addNewClass.importSettings")}</li>
                      </PopRow>
                      <PopRow
                        onClick={() => {
                          handleClose()
                          handleOpenDialogDeleteClass()
                        }}
                      >
                        <li>{t("addNewClass.removeClass")}</li>
                      </PopRow>
                    </ul>
                  </PopOverStyle>
                </BoxStyle>
              ))}

            <Dialog
              titleText={t("addNewClass.editClass")}
              actionAcceptText={t("addNewClass.save")}
              open={isDialogOpenEditClass}
              onClose={() => {
                handleCloseDialogEditClass()
                setState(initialState)
                setOpen(false)
                mixpanel.track("autopay_state", {
                  action: "cancel",
                  origin: "edit_class",
                })
              }}
              desktopMaxWidth="534px"
              tabletMaxWidth="534px"
              actionAcceptButtonProps={{ type: "submit", form: "editForm" }}
              onActionButtonClick={() => {
                setOpen(false)
                mathClassMutation(mathOptionStates)
              }}
            >
              <AddNewClass
                dispatchState={dispatchState}
                state={state}
                setState={setState}
                id="editForm"
                editClass={editClass}
                getDataId={getDataId?.data}
                isEditClass
                userInfo={userInfo}
              />
            </Dialog>

            <Dialog
              open={isDialogOpenDeleteClass}
              onClose={handleCloseDialogDeleteClass}
              titleText={t("addNewClass.areYouSureYouWantToDeleteClass", {
                class: className,
              })}
              desktopMaxWidth="534px"
              tabletMaxWidth="534px"
              onActionButtonClick={() => {
                id && deleteClass({ classId: id })
              }}
            >
              <Typography
                variant="body1"
                fontWeight="700"
                color="mockup.error50"
              >
                {t("addNewClass.thisActionCannotBeUndone").toUpperCase()}
              </Typography>
            </Dialog>
            <Dialog
              open={isDialogOpenApplySettings}
              onClose={() => {
                handleCloseDialogApplySettings()
                cleanAfterCloseDialog()
                mixpanel.track("import_class_settings", {
                  action: "cancel",
                })
              }}
              titleText={t("addNewClass.importSettingsTo", {
                className: className,
              })}
              desktopMaxWidth="534px"
              tabletMaxWidth="534px"
              actionAcceptText={
                isLoadingSave
                  ? t("addNewClass.copyingItems")
                  : t("addNewClass.save")
              }
              actionAcceptButtonProps={{
                form: "apply",
                disabled: isLoadingSave,
                type: "submit",
              }}
            >
              <ApplySettings
                setIsLoading={setIsLoadingSave}
                cleanAfterCloseDialog={cleanAfterCloseDialog}
                id="apply"
                getDataId={getDataId?.data}
                handleCloseDialogApplySettings={handleCloseDialogApplySettings}
              />
            </Dialog>
          </Stack>
        </Stack>
      </Stack>

      {process.env.REACT_APP_TYPE === "classequity" && (
        <a
          href="https://help.classbank.com/en/"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            sx={{
              cursor: "pointer",
              ":hover": {
                transition: "opacity .2s",
                opacity: ".7",
              },
            }}
            mt={2}
            padding="12px"
            columnGap="10px"
            display="flex"
            color={theme.palette.mockup.neutral100}
            width="100%"
            alignItems="center"
          >
            <IconQuestion />
            <Typography>Help center</Typography>
          </Box>
        </a>
      )}
    </Stack>
  )
}

export default Navigation
